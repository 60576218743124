import { GraphKeys } from '../Keys'

import { useQuery } from '@tanstack/react-query'
import axios, { AxiosError } from 'axios'
import { CLIENT_ID } from '@msteams/constants'
import { useGetOne } from '@msteams/api/queries/request'

const PATHS = {
  confirmConsents: '/integrations/microsoft/consent',
  token: '/integrations/microsoft/graph',
}

const ARA_PATH = `https://graph.microsoft.com/v1.0/servicePrincipals(appId='${CLIENT_ID}')/appRoleAssignments`

export const useConfirmConsentStatus = () => {
  return useGetOne([GraphKeys.ConfirmConsent], PATHS.confirmConsents, {
    query: {
      enabled: false,
      retry: 0,
    },
  })
}

export const useGetGraphToken = () => {
  return useGetOne<{ access_token: string }>([GraphKeys.GraphToken], PATHS.token, {
    query: {
      enabled: true,
      retry: 0,
    },
  })
}

export const useGetAppRoleAssignments = () => {
  const { data } = useGetGraphToken()
  return useQuery<{ value: { appRoleId: string }[] }, AxiosError>([GraphKeys.AppRoleAssignments], {
    queryFn: async () => {
      try {
        const appRoleAssignments = await axios.get(ARA_PATH, {
          headers: {
            Authorization: 'Bearer ' + data?.access_token,
          },
        })
        return appRoleAssignments.data
      } catch (e) {
        if ((e as AxiosError).request?.status === 403) {
          return []
        } else {
          throw e
        }
      }
    },
    enabled: !!data?.access_token,
  })
}

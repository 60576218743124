export const AuthKeys = {
  Token: 'Token',
}

export const DashboardKeys = {
  Dashboard: 'Dashboard',
}

export const GraphKeys = {
  Consent: 'GraphConsent',
  ConfirmConsent: 'ConfirmConsent',
  GraphToken: 'GraphToken',
  AppRoleAssignments: 'AppRoleAssignments',
}

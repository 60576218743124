import React from 'react'
import { createRoot } from 'react-dom/client'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import * as Sentry from '@sentry/react'

import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom'
import './i18n'

import MSTeamsApp from '@msteams/App'
import { SENTRY_DSN } from './constants'

import 'react-datepicker/dist/react-datepicker.css'
import '@szhsin/react-menu/dist/index.css'
import '@szhsin/react-menu/dist/transitions/slide.css'

import './index.css'

if (SENTRY_DSN) {
  Sentry.init({
    dsn: SENTRY_DSN,
    integrations: [
      Sentry.captureConsoleIntegration({
        levels: ['error'],
      }),
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect: React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.browserTracingIntegration(),
    ],
    tracesSampleRate: 1.0,
  })
}
const queryClient = new QueryClient({
  defaultOptions: { queries: { refetchOnWindowFocus: false, retry: false } },
})

const MSTeamsAppCmp = (
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <MSTeamsApp />
    </QueryClientProvider>
  </React.StrictMode>
)

const root = createRoot(document.getElementById('root')!)

root.render(MSTeamsAppCmp)

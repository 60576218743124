import React, { useEffect, useMemo, useRef, useState } from 'react'

import OrganizationMenu from '@components/Navigation/OrganizationMenu'
import LinkList from '@msteams/components/Navigation/components/LinksList'
import SelectNavigationList from '@msteams/components/Navigation/components/SelectNavigationList'
import LinkMenu from '@msteams/components/Navigation/components/LinkMenu'

import { useIsAdmin } from '@src/helpers'
import { getSingleLinks } from '@msteams/components/Navigation/utils'

const Navigation = () => {
  const isAdmin = useIsAdmin()
  const [isScrolled, setIsScrolled] = useState(false)

  const navRef = useRef<HTMLDivElement>(null)

  const links = useMemo(() => getSingleLinks(isAdmin), [isAdmin])
  const otherLinks = useMemo(() => links?.slice(4, links?.length) || [], [links])

  useEffect(() => {
    const clientWidth = navRef.current?.clientWidth || 0
    const scrollWidth = navRef.current?.scrollWidth || 0

    const isScrolled = scrollWidth > clientWidth
    if (isScrolled) {
      setIsScrolled(true)
    }
  }, [navRef.current])

  return (
    <div
      className="flex gap-2 mb-3 flex-col md:flex-row  md:items-center pb-2 pt-1 overflow-hidden w-full"
      ref={navRef}
    >
      <OrganizationMenu align="start" direction="bottom" classes={{ menu: ' !ml-0 !mt-1 w-[224px]', button: 'mx-1' }} />
      <LinkList links={links?.slice(0, 4)} />
      <SelectNavigationList />
      {!isScrolled && <LinkList links={otherLinks} />}
      {isScrolled && <LinkMenu links={otherLinks} />}
    </div>
  )
}

export default Navigation

import {
  useQuery,
  useMutation,
  QueryKey,
  UseQueryOptions,
  UseMutationOptions,
  MutationFunction,
  QueryFunction,
} from '@tanstack/react-query'
import { AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios'

import api from '../api'

type URL<TRequest> = ((data?: TRequest) => string) | string

function getPath<TRequest>(url: URL<TRequest>, data?: TRequest) {
  return typeof url === 'string' ? url : url(data)
}

interface Params<TRes, TReq = unknown, TErr = unknown> {
  axios?: AxiosRequestConfig
  query?: UseQueryOptions<TRes, AxiosError<TErr>, TRes>
  mutation?: Omit<UseMutationOptions<TRes, AxiosError<TErr>, TReq>, 'mutationFn'>
  function?: MutationFunction<TRes, TReq> | QueryFunction<TRes>
}

export const useGetOne = <TResponse = unknown, TError = unknown>(
  key: QueryKey,
  url: string,
  params?: Params<TResponse, unknown, TError>,
) => {
  const service: QueryFunction<TResponse> = async (): Promise<TResponse> => {
    const { data }: AxiosResponse<TResponse> = await api.get(url, params?.axios)
    return data
  }
  return useQuery<TResponse, AxiosError<TError>>({
    ...params?.query,
    queryKey: key,
    queryFn: (params?.function as QueryFunction<TResponse>) || service,
  })
}

export const useGetList = <TResponse = unknown>(key: QueryKey, url: string, params?: Params<TResponse[]>) => {
  const service: QueryFunction<TResponse[]> = async (): Promise<TResponse[]> => {
    const { data }: AxiosResponse<TResponse[]> = await api.get(`${url}`, params?.axios)
    return data
  }
  return useQuery<TResponse[], AxiosError>({ ...params?.query, queryKey: key, queryFn: service })
}

export const useCreate = <TRequest, TResponse = unknown, TError = unknown>(
  url: URL<TRequest>,
  params?: Params<TResponse, TRequest, TError>,
) => {
  const service: MutationFunction<TResponse, TRequest> = async (data: TRequest): Promise<TResponse> => {
    const res: AxiosResponse<TResponse> = await api.post(`${getPath(url, data)}`, data, params?.axios)

    return res.data
  }

  return useMutation<TResponse, AxiosError<TError>, TRequest>(
    (params?.function as MutationFunction<TResponse, TRequest>) || service,
    params?.mutation,
  )
}

export const useUpdate = <TRequest, TResponse = unknown, TError = AxiosError>(
  url: URL<TRequest>,
  params?: Params<TResponse, TRequest>,
) => {
  const service: MutationFunction<TResponse, TRequest> = async (data: TRequest): Promise<TResponse> => {
    const res: AxiosResponse<TResponse> = await api.put(`${getPath(url, data)}`, data, params?.axios)

    return res.data
  }

  return useMutation<TResponse, AxiosError<TError>, TRequest>(service, params?.mutation)
}

export const usePatch = <TRequest, TResponse = unknown, TError = AxiosError>(
  url: URL<TRequest>,
  params?: Params<TResponse, TRequest>,
) => {
  const service: MutationFunction<TResponse, TRequest> = async (data?: TRequest): Promise<TResponse> => {
    const res: AxiosResponse<TResponse> = await api.patch(`${getPath(url, data)}`, data || undefined, params?.axios)

    return res.data
  }

  return useMutation<TResponse, AxiosError<TError>, TRequest>(service, params?.mutation)
}

export const useDelete = <TRequest, TResponse = null>(url: URL<TRequest>, params?: Params<TResponse, TRequest>) => {
  const service: MutationFunction<TResponse, TRequest> = async (data: TRequest): Promise<TResponse> => {
    const res: AxiosResponse<TResponse> = await api.delete(`${getPath(url, data)}`, { ...params?.axios, data })

    return res.data
  }

  return useMutation<TResponse, AxiosError, TRequest>(service, params?.mutation)
}

import { Feature } from '@type/features'
import { JSX } from 'react'

export enum LinkGroupType {
  payable = 'payable',
  receivable = 'receivable',
}

export type LinkGroupData = {
  getOptions(isAdmin: boolean): Option<string>[]
  isActive(path: string): boolean
  type: LinkGroupType
  feature?: Feature
}

export type SingleLinkObject = {
  to: string
  label: string | JSX.Element
  isActive: (path: string) => boolean
  feature?: Feature
}

import React from 'react'

import Loading from '@components/Loading'

import { useIsFetching, useIsMutating } from '@tanstack/react-query'
import { loadableMutations, loadableQueries } from '@queries/Keys'

const Loader = () => {
  const isMutating = useIsMutating({
    predicate: (mutation) => {
      const key = mutation?.options?.mutationKey?.[0]
      return loadableMutations.includes(key as string)
    },
  })
  const isFetching = useIsFetching({
    predicate: (query) => {
      const key = Array.isArray(query.queryKey) ? query.queryKey[0] : query.queryKey
      return loadableQueries.includes(key)
    },
  })

  return <Loading loading={!!(isMutating || isFetching)} />
}

export default Loader

import React from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import cx from 'clsx'

import { useGetIsFeatureAvailable } from '@helpers/hooks/useGetHideWithPaywall'
import { SingleLinkObject } from '@msteams/components/Navigation/types'
import Lock from '@msteams/components/Navigation/components/Lock'

const Link = ({ link }: { link: SingleLinkObject }) => {
  const { pathname } = useLocation()
  const { available, paywallPath } = useGetIsFeatureAvailable(link.feature)

  const to = (!available ? paywallPath : link.to) || ''

  return (
    <NavLink
      color="white"
      to={to}
      className={cx(
        '!text-gray-700 border-2 border-gray-200 rounded-lg flex gap-1 px-3 py-1 font-medium !h-10 items-center',
        'block whitespace-nowrap',
        link.isActive(pathname) && '!bg-purple-50 !text-violet-700 !font-medium border-transparent',
        !link.isActive(pathname) && 'hover:border-purple-300',
        !available && 'bg-gray-100',
      )}
    >
      {link.label}
      {!available && <Lock />}
    </NavLink>
  )
}

const LinkList = ({ links }: { links: SingleLinkObject[] }) => {
  return (
    <>
      {links.map((link, i) => (
        <Link link={link} key={i} />
      ))}
    </>
  )
}
export default LinkList

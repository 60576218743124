import React, { FC, useEffect, useMemo } from 'react'
import { generatePath, NavLink, useLocation, useNavigate } from 'react-router-dom'
import { FormikErrors, useFormik } from 'formik'
import { components } from 'react-select'

import { Select } from '@components/Form'

import { LinkGroups, LinkGroupStylesConfig, LinkGroupTitles } from '@msteams/components/Navigation/utils'

import { LinkGroupData } from '@msteams/components/Navigation/types'
import { useIsAdmin } from '@src/helpers'
import { FeaturesToPaywall, useGetIsFeatureAvailable } from '@helpers/hooks/useGetHideWithPaywall'
import Lock from '@msteams/components/Navigation/components/Lock'
import { useGetTeamSubscription } from '@queries/Billing'
import routes from '@src/Routes/routes'
import { Feature } from '@type/features'
import { EventHandler } from '@components/Form/Select'

interface FormProps {
  receivable: string | null
  payable: string | null
  activeLinkGroup: LinkGroupData | null
}

interface SelectComponentProps {
  linkGroup: LinkGroupData
  setFieldValue: (
    field: string,
    value: FormProps[keyof FormProps],
    shouldValidate?: boolean,
  ) => Promise<FormikErrors<FormProps>> | Promise<void>
  values: FormProps
}

const SelectComponent: FC<SelectComponentProps> = ({ linkGroup, setFieldValue, values }) => {
  const isAdmin = useIsAdmin()
  const { available, paywallPath } = useGetIsFeatureAvailable(linkGroup.feature)
  const { data: subscription } = useGetTeamSubscription()

  const navigate = useNavigate()
  const options = useMemo(() => linkGroup.getOptions(isAdmin), [isAdmin, linkGroup])

  const handlePathSelect: EventHandler = (e) => {
    const path = e.target.value as string
    const selectedLink = options.find((option) => option.value === path)
    const selectedLinkFeature = selectedLink?.feature as Feature
    const available = selectedLinkFeature ? subscription?.plan_features?.[selectedLinkFeature] : true

    const navigateTo = available
      ? path
      : generatePath(routes.private.paywall, { feature: FeaturesToPaywall[selectedLinkFeature] })

    navigate(navigateTo || '')
    setFieldValue(linkGroup.type, path)
    if (values.activeLinkGroup && values.activeLinkGroup?.type !== linkGroup.type) {
      setFieldValue(values.activeLinkGroup.type, null)
    }
    setFieldValue('activeLinkGroup', linkGroup)
  }

  const SelectCmp = () => (
    <Select
      className="!mb-0"
      options={options}
      onChange={handlePathSelect}
      placeholder={LinkGroupTitles[linkGroup.type]}
      value={values[linkGroup.type] || undefined}
      isSearchable={false}
      isDisabled={!available}
      classes={{}}
      components={{
        IndicatorSeparator: null,
        DropdownIndicator: (props) => {
          return !available ? (
            <div className="mr-2">
              <Lock />
            </div>
          ) : (
            <components.DropdownIndicator {...props} />
          )
        },
        SingleValue: (props) => {
          return (
            <components.SingleValue {...props}>
              {LinkGroupTitles[linkGroup.type]}: {props.children}
            </components.SingleValue>
          )
        },
        Option: (props) => {
          const { available } = useGetIsFeatureAvailable(props.data.feature as Feature)
          return (
            <components.Option {...props}>
              <div className="flex justify-between">
                {props.children}
                {!available && <Lock />}
              </div>
            </components.Option>
          )
        },
      }}
      styles={LinkGroupStylesConfig}
    />
  )

  if (available) {
    return <SelectCmp />
  } else {
    return (
      <NavLink to={paywallPath!} className="border-2 border-transparent hover:border-purple-300 rounded-[10px]">
        <SelectCmp />
      </NavLink>
    )
  }
}

const SelectNavigationList = () => {
  const { pathname } = useLocation()

  const formik = useFormik<FormProps>({
    initialValues: {
      receivable: null,
      payable: null,
      activeLinkGroup: null,
    },
    onSubmit: () => {
      return undefined
    },
  })

  useEffect(() => {
    const { activeLinkGroup } = formik.values
    if (activeLinkGroup && !activeLinkGroup.isActive(pathname)) {
      formik.setFieldValue(activeLinkGroup.type, null)
    }
  }, [pathname])

  return (
    <>
      {LinkGroups.map((linkGroup, i) => (
        <SelectComponent setFieldValue={formik.setFieldValue} values={formik.values} key={i} linkGroup={linkGroup} />
      ))}
    </>
  )
}

export default SelectNavigationList

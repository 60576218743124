import React, { useEffect, useMemo, useState } from 'react'
import { RouterProvider } from 'react-router-dom'
import * as Sentry from '@sentry/react'

import { GlobalContext } from '@msteams/GlobalContext'

import ErrorFallback from '@components/ErrorBoundary/ErrorBoundaryFallback'
import Toast from '@components/Toast'
import Loading from '@components/Loading'
import Loader from '@msteams/components/Loader'

import { personalRouter, teamRouter } from '@msteams/Routes/'

import { AppScope, DeepLinkEntityType } from '@msteams/types/common'
import { app } from '@microsoft/teams-js'
import { useTeamsUserCredential } from '@microsoft/teamsfx-react'
import { CLIENT_ID, START_LOGIN_PAGE } from '@msteams/constants'
import { TeamsFxContext } from '@msteams/Context'

import styles from './App.module.css'

const routers = {
  [AppScope.personal]: personalRouter,
  [AppScope.team]: teamRouter,
}
export default function App() {
  const [deepLinkEntityId, setDeepLinkEntityId] = useState<string | undefined>()
  const [deepLinkEntityType, setDeepLinkEntityType] = useState<DeepLinkEntityType | undefined>()
  const [scope, setScope] = useState<AppScope>()

  const { teamsUserCredential } = useTeamsUserCredential({
    clientId: CLIENT_ID as string,
    initiateLoginEndpoint: START_LOGIN_PAGE as string,
  })

  const content = useMemo(() => {
    if (!scope) return null
    const router = routers[scope]
    return router ? <RouterProvider router={router} /> : null
  }, [scope])

  useEffect(() => {
    app.getContext().then((context) => {
      const subPageId = context.page?.subPageId
      const [entityType, entityId] = subPageId?.split(':') || []
      setScope(context.team ? AppScope.team : AppScope.personal)
      setDeepLinkEntityId(entityId)
      setDeepLinkEntityType(entityType as DeepLinkEntityType)
    })
  }, [])

  return (
    <Sentry.ErrorBoundary fallback={ErrorFallback}>
      <GlobalContext.Provider
        value={{
          deepLinkEntityId,
          setDeepLinkEntityId,
          deepLinkEntityType,
          scope,
          setScope,
        }}
      >
        <TeamsFxContext.Provider value={{ teamsUserCredential }}>
          <React.Suspense fallback={<Loading loading />}>
            <div className={styles.app}>{content}</div>
          </React.Suspense>
        </TeamsFxContext.Provider>
        <Toast />
        <Loader />
      </GlobalContext.Provider>
    </Sentry.ErrorBoundary>
  )
}

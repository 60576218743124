import React from 'react'

import i18n from 'i18next'
import routes from '@msteams/Routes/routes'
import { generatePath, matchPath } from 'react-router-dom'
import { ContactParty } from '@models/ApproveitAccounting'
import { LinkGroupData, LinkGroupType, SingleLinkObject } from '@msteams/components/Navigation/types'
import { ControlProps, CSSObjectWithLabel, DropdownIndicatorProps, SingleValueProps } from 'react-select'
import { IconSettings } from '@tabler/icons-react'
import { Feature } from '@type/features'
import { FeaturesPaywall } from '@helpers/hooks/useGetHideWithPaywall'

export const LinkGroupTitles: Record<LinkGroupType, string> = {
  [LinkGroupType.payable]: i18n.t('common:navigation.accountsPayable'),
  [LinkGroupType.receivable]: i18n.t('common:navigation.accountsReceivable'),
}

export const LinkGroups: LinkGroupData[] = [
  {
    getOptions: (isAdmin: boolean) => {
      const options = [
        { label: i18n.t('common:navigation.bills'), value: routes.private.bills },
        {
          label: i18n.t('common:navigation.purchaseOrders'),
          value: routes.private.purchaseOrders,
        },
        {
          label: i18n.t('common:navigation.rfq'),
          value: routes.private.rfqs,
        },
      ]
      if (isAdmin) {
        options.push({
          label: i18n.t('common:navigation.vendors'),
          value: generatePath(routes.private.contacts, { contactParty: ContactParty.vendor }),
        })
      }
      return options
    },
    isActive: (path: string) => {
      return !![
        routes.private.purchaseOrders,
        routes.private.purchaseOrdersById,
        routes.private.bills,
        routes.private.billById,
        routes.private.contactsList,
        routes.private.contact,
        routes.private.contactContract,
        routes.private.contactContracts,
        routes.private.rfqs,
        routes.private.rfqProposalsById,
        routes.private.rfqById,
        routes.private.costCenter,
        routes.private.costCenterById,
        routes.private.costCenterBudget,
        routes.private.costCenterEditById,
        routes.private.financeSettings,
        routes.private.accountCategories,
        routes.private.financeSettings,
        routes.private.accounts,
        routes.private.accountById,
        routes.private.accountCategories,
        routes.private.accountCategoryById,
        routes.private.companyDetails,
        routes.private.taxes,
        routes.private.taxById,
        routes.private.products,
        routes.private.productById,
        generatePath(routes.private.paywall, { feature: FeaturesPaywall.CostCenters }),
      ].find((route) => matchPath(route, path))
    },
    type: LinkGroupType.payable,
    feature: Feature.accountsPayable,
  },
  {
    getOptions: (isAdmin: boolean) => {
      const options = [
        { label: i18n.t('common:navigation.invoices'), value: routes.private.invoices },
        {
          label: i18n.t('common:navigation.quotes'),
          value: routes.private.quotes,
        },
        {
          label: i18n.t('common:navigation.costCenters'),
          value: routes.private.costCenter,
          feature: Feature.costCenters,
        },
      ]
      if (isAdmin) {
        options.push({
          label: i18n.t('common:navigation.customers'),
          value: generatePath(routes.private.contacts, { contactParty: ContactParty.customer }),
        })
      }
      return options
    },
    isActive: (path: string) => {
      return !![
        routes.private.invoices,
        routes.private.invoiceById,
        routes.private.quotes,
        routes.private.quoteById,
        routes.private.contactsList,
        routes.private.contact,
        routes.private.contactContract,
        routes.private.costCenter,
        routes.private.costCenterNew,
        routes.private.costCenterById,
        routes.private.costCenterEditById,
        routes.private.costCenterBudget,
        routes.private.costCenterBreakdown,
      ].find((route) => matchPath(route, path))
    },
    feature: Feature.accountsReceivable,
    type: LinkGroupType.receivable,
  },
]

export const getSingleLinks = (isAdmin: boolean): SingleLinkObject[] => {
  const links = [
    {
      to: routes.private.settings,
      label: <IconSettings />,
      isActive: (path: string) => !![routes.private.settingsRouted].find((match) => matchPath(match, path)),
    },
    {
      to: routes.private.approvalRequests,
      label: i18n.t('common:navigation.approvalRequests'),
      isActive: (path: string) =>
        !![
          routes.private.approvalRequests,
          routes.private.approvalRequests,
          routes.private.approvalRequestsById,
          routes.private.approvalRequestsByIdAction,
          routes.private.approvalRequest,
          routes.private.approvalRequestEditById,
        ].find((match) => matchPath(match, path)),
    },
    {
      to: routes.private.pipelines,
      label: i18n.t('common:navigation.workflows'),
      isActive: (path: string) =>
        !![
          routes.private.processes,
          routes.private.pipelineProcesses,
          routes.private.pipelines,
          routes.private.pipelineProcessesById,
          routes.private.pipelineProcesses,
          routes.private.pipelineApprovalRequest,
          routes.private.pipelineById,
        ].find((match) => matchPath(match, path)),
    },
    {
      to: routes.private.tasks,
      label: i18n.t('common:navigation.tasks'),
      isActive: (path: string) =>
        !!(matchPath(routes.private.tasks, path) || matchPath(routes.private.taskBoards, path)),
    },
    {
      to: routes.private.reports,
      label: i18n.t('common:navigation.reports'),
      isActive: (path: string) =>
        !![
          routes.private.reports,
          routes.private.aiReports,
          routes.private.createReport,
          routes.private.reportById,
        ].find((match) => matchPath(match, path)),
    },
    isAdmin
      ? {
          to: routes.private.mailbox,
          label: i18n.t('common:navigation.mailbox'),
          isActive: (path: string) => matchPath(routes.private.mailbox, path),
          feature: Feature.mailbox,
        }
      : null,
    isAdmin
      ? {
          to: routes.private.organizationalStructure,
          label: i18n.t('common:navigation.team'),
          isActive: (path: string) =>
            [
              routes.private.organizationalStructure,
              routes.private.teamDepartments,
              routes.private.teamDepartmentById,
              routes.private.teamLocations,
              routes.private.teamLocationById,
            ].find((match) => matchPath(match, path)),
        }
      : null,
    isAdmin
      ? {
          to: routes.private.contracts,
          label: i18n.t('common:navigation.contracts'),
          isActive: (path: string) => !!matchPath(routes.private.contracts, path),
          feature: Feature.contractManagement,
        }
      : null,
  ]

  return links.filter(Boolean) as SingleLinkObject[]
}

export const LinkGroupStylesConfig = {
  control: (base: CSSObjectWithLabel, props: ControlProps) => {
    return {
      ...base,
      cursor: 'pointer',
      backgroundColor: props.selectProps.value ? ' rgb(250, 245, 255,1)' : base.backgroundColor,
      borderRadius: 8,
      borderWidth: '2px',
      borderColor: 'rgb(229 231 235)',
      '&:hover': {
        borderColor: 'rgb(216 180 254 )',
      },
    }
  },
  dropdownIndicator: (base: CSSObjectWithLabel, props: DropdownIndicatorProps) => ({
    ...base,
    transform: props.selectProps.menuIsOpen ? 'rotate(180deg)' : '',
  }),
  placeholder: (base: CSSObjectWithLabel) => ({
    ...base,
    color: 'rgba(55, 65, 81, 1)',
    fontWeight: '500',
  }),
  singleValue: (base: CSSObjectWithLabel, props: SingleValueProps) => ({
    ...base,
    color: props.selectProps.value ? ' rgba(67, 56, 202, 1)' : 'black',
    fontWeight: '500',
  }),
  valueContainer: (base: CSSObjectWithLabel) => ({
    ...base,
    whiteSpace: 'nowrap',
  }),
}

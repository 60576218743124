import React, { useContext } from 'react'
import { Outlet } from 'react-router-dom'

import Navigation from '@msteams/components/Navigation'
import GlobalContext from '@msteams/GlobalContext'
import NoConsentGrantedBanner from '@msteams/components/NoConsentGrantedBanner'

import { AppScope } from '@msteams/types/common'

const AppLayout = () => {
  const { scope } = useContext(GlobalContext)

  return (
    <>
      <NoConsentGrantedBanner />
      <div className="px-3 py-4 h-full">
        {scope === AppScope.personal && <Navigation />}
        <Outlet />
      </div>
    </>
  )
}
export default AppLayout

import { useGetAppRoleAssignments } from '@msteams/api/queries/Graph'
import { useMemo } from 'react'
import { ROLES_FOR_CONSENT } from '@msteams/constants'

const useCheckIsConsentGranted = () => {
  const { data: permissionsData, refetch, isFetched } = useGetAppRoleAssignments()
  const isConsentGranted = useMemo(() => {
    if (!permissionsData && !isFetched) {
      return true
    }
    const permissions = permissionsData?.value || []
    const givenAppRoles = permissions.map((role: { appRoleId: string }) => role.appRoleId)
    return ROLES_FOR_CONSENT.every((roleId) => givenAppRoles.includes(roleId))
  }, [permissionsData, isFetched])

  return { isConsentGranted, refetch }
}

export default useCheckIsConsentGranted

import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import LinkButton from '@components/LinkButton'
import Popup from '@components/Popup'
import usePopupHandler from '@components/Popup/usePopupHandler'
import Button from '@components/Button'
import { CONSENT_URL, SUPPORT_EMAIL } from '@msteams/constants'
import { IconRefresh } from '@tabler/icons-react'
import cx from 'clsx'

import styles from './index.module.css'
import useCheckIsConsentGranted from '@msteams/hooks/useCheckIsConsentGranted'
import { toast } from '@helpers/toaster'
import { useConfirmConsentStatus } from '@msteams/api/queries/Graph'

const MAX_PINGS = 50

const NoConsentGrantedBanner = () => {
  const { t } = useTranslation('msteams', { keyPrefix: 'noConsentBanner' })
  const [isCheckingConsentGranted, setIsCheckingConsentGranted] = useState(false)
  const [pingCounter, setPingCounter] = useState<number>(0)
  const { isPopupOpen, setIsPopupOpen } = usePopupHandler()
  const { isConsentGranted, refetch } = useCheckIsConsentGranted()
  const { refetch: confirmConsentsGrant } = useConfirmConsentStatus()

  const isCheckingConsentGrantedRef = useRef(false)

  useEffect(() => {
    isCheckingConsentGrantedRef.current = isConsentGranted
  }, [isConsentGranted])

  const pingConsents = useCallback(async () => {
    if (pingCounter >= MAX_PINGS) {
      return
    }
    if (!isCheckingConsentGrantedRef.current) {
      await refetch()
      setTimeout(pingConsents, 5000)
      setPingCounter((value) => value + 1)
    } else {
      setIsCheckingConsentGranted(false)
      confirmConsentsGrant()
      toast.success(t('successToast'))
    }
  }, [isConsentGranted])

  const handleGrantConsentClick = useCallback(() => {
    setIsPopupOpen(false)
    setIsCheckingConsentGranted(true)
    pingConsents()
  }, [isConsentGranted])

  if (isConsentGranted) {
    return null
  }

  return (
    <>
      <div className="h-12 bg-violet-700 flex justify-center items-center gap-1.5 text-white ">
        <span className="text-lg font-medium text-center ">
          {isCheckingConsentGranted ? (
            t('checkingMessage')
          ) : (
            <Trans
              t={t}
              i18nKey="title"
              components={[<button onClick={() => setIsPopupOpen(true)} className="underline" key={0} />]}
            />
          )}
        </span>
        {isCheckingConsentGranted && <IconRefresh className={cx(styles.rotating)} />}
      </div>
      {isPopupOpen && (
        <Popup
          className="text-center max-w-[45rem] flex flex-col items-center p-6"
          onClose={() => setIsPopupOpen(false)}
        >
          <img src="/msteams/sync.png" className="w-[15rem]" />
          <p className="font-bold text-xl mt-5">{t('popupTitle')}</p>
          <p className="text-lg mt-3">{t('popupDescription')}</p>
          <div className="flex justify-center gap-1.5 mt-5 w-full">
            <Button onClick={() => setIsPopupOpen(false)} className="min-w-[7.5rem]" color="white">
              {t('back')}
            </Button>
            <LinkButton to={`mailto:${SUPPORT_EMAIL}`} className="min-w-[7.5rem]" color="white">
              {t('contactSupport')}
            </LinkButton>
            <LinkButton
              to={CONSENT_URL}
              target="_blank"
              className="min-w-[7.5rem]"
              color="success"
              onClick={handleGrantConsentClick}
            >
              {t('grantConsent')}
            </LinkButton>
          </div>
        </Popup>
      )}
    </>
  )
}
export default NoConsentGrantedBanner

import React, { useEffect, useMemo } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import routes from '@msteams/Routes/routes'
import { useGetMyAccount } from '@queries/Account'

const OnboardingLayout = () => {
  const navigate = useNavigate()
  const { data: account, isInitialLoading } = useGetMyAccount()

  const isOnboarded = useMemo(() => {
    return !!account?.team?.onboarded
  }, [account])

  useEffect(() => {
    if (!isOnboarded && !isInitialLoading) {
      navigate(routes.private.onboarding)
    }
  }, [isOnboarded, isInitialLoading])

  return <Outlet />
}
export default OnboardingLayout

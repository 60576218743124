import React from 'react'
import { Menu, MenuButton, MenuItem } from '@szhsin/react-menu'
import cx from 'clsx'
import { NavLink, useLocation } from 'react-router-dom'

import { IconDots } from '@tabler/icons-react'
import Button from '@components/Button'
import { SingleLinkObject } from '@msteams/components/Navigation/types'
import { useGetIsFeatureAvailable } from '@helpers/hooks/useGetHideWithPaywall'
import Lock from '@msteams/components/Navigation/components/Lock'

const SingleLink = ({ link }: { link: SingleLinkObject }) => {
  const { pathname } = useLocation()
  const { available, paywallPath } = useGetIsFeatureAvailable(link.feature)
  const path = !available && paywallPath ? paywallPath : link.to

  return (
    <MenuItem
      className={cx(
        link.isActive(pathname) && '!bg-purple-50 !text-violet-700 !font-medium border-transparent',
        'px-0',
      )}
    >
      <NavLink to={path!} className="flex justify-between w-full px-4">
        <span>{link.label}</span>
        <span>{!available && <Lock />}</span>
      </NavLink>
    </MenuItem>
  )
}

const LinkMenu = ({ links }: { links: SingleLinkObject[] }) => {
  return (
    <div>
      <Menu
        direction="bottom"
        position="initial"
        align="end"
        menuButton={
          <MenuButton>
            <Button className="p-0 h-9" color="white">
              <IconDots />
            </Button>
          </MenuButton>
        }
      >
        {links.map((link, i) => (
          <SingleLink link={link} key={i} />
        ))}
      </Menu>
    </div>
  )
}

export default LinkMenu
